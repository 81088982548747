import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { withRoomContext } from "../RoomContext";
import { useSelector } from "react-redux";
import { MenuItem } from "@mui/material";

const LiveStreamDialog = (props) => {
  const breakOutRoomsActive = useSelector(
    (state) => state.conf.breakOutRoomsActive
  );
  const breakOutRooms = useSelector((state) => state.conf.breakOutRooms);
  const ers = useSelector((state) => state.conf.ers);
  const ersu = useSelector((state) => state.conf.ersu);
  const ersk = useSelector((state) => state.conf.ersk);
  const [state, setState] = React.useState({
    streamUrl: ersu || "",
    streamKey: ersk || "",
    breakOutRoom: "",
  });
  const handleSave = () => {
    if (state.streamKey === "" || state.streamUrl === "") {
      props.roomClient.showNotification(
        "danger",
        "Live streaming Error!",
        "Both Stream Url and Stream key are required to start the Live Stream!"
      );
      return;
    }
    if (breakOutRoomsActive && state.breakOutRoom === "") {
      props.roomClient.showNotification(
        "danger",
        "Live streaming Error!",
        "Select a breakout room to Live stream!"
      );
      return;
    }
    props.handleClose();
    // alert(JSON.stringify(state));
    props.roomClient.handleLiveStreaming(state);
  };
  const handleChange = (e) => {
    // console.log("in handle change", e.target);
    setState(() => {
      return { ...state, [e.target.name]: e.target.value };
    });
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Live Stream Settings</DialogTitle>
        {ers ? (
          <DialogContent>
            <DialogContentText>
              Please provide the Live stream url and the key value below. It can
              be youtube/facebook/any other RTMP destination.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="streamUrl"
              name="streamUrl"
              label="Live Stream Url"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={state.streamUrl}
              required
            />
            <TextField
              // autoFocus
              margin="dense"
              id="streamKey"
              name="streamKey"
              label="Stream Key"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={state.streamKey}
              required
            />
            {breakOutRoomsActive ? (
              <TextField
                // autoFocus
                margin="dense"
                id="breakOutRoom"
                name="breakOutRoom"
                label="Breakout Room to Live Stream"
                fullWidth
                select
                variant="standard"
                onChange={handleChange}
                value={state.breakOutRoom}
              >
                {Object.keys(breakOutRooms).map((breakOutRoom, index) =>
                  breakOutRoom === "unAssigned" ? null : (
                    <MenuItem key={index} value={breakOutRoom}>
                      {breakOutRoom}
                    </MenuItem>
                  )
                )}
              </TextField>
            ) : null}
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText>
              Live streaming is not enabled on this room. If you are the admin,
              please check your org. settings / room settings to enable this
              option.
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button variant="outlined" onClick={props.handleClose}>
            Cancel
          </Button>
          {ers ? (
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default withRoomContext(LiveStreamDialog);
