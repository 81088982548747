import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import {
  Button,
  InputAdornment,
  TextField,
  Snackbar,
  Grid,
  Typography,
} from "@mui/material";
import styles from "./app.module.scss";
import { connect } from "react-redux";
import {
  setUrl,
  setAuth,
  setNotifications,
  setPaypal,
} from "./actions/connectionActions";
// import ReactNotification from "react-notifications-component"
// import "react-notifications-component/dist/theme.css"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css";
// import ProfileModify from "./components/ProfileModify/ProfileModify";
// eslint-disable-next-line
// import {// eslint-disable-next-line
//   join,// eslint-disable-next-line
//   send// eslint-disable-next-line
// } from './components/SocketIo/Socket'
import axios from "axios";
import { CssBaseline } from "@mui/material";
import Bowser from "bowser";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import Loading from "./components/common/Loading";
import PrivateRoute from "./components/common/PrivateRoute";
import componentLoader from "./utils/componentLoader";
import Feedback from "./components/Feedback/Feedback";
import theme from "./components/ui/Theme";
import UserFeedbackSnackbar from "./components/common/UserFeedbackSnackBar";
import DefaultLayout from "./components/DefaultLayout/DefaultLayout";
import ErrorBoundary from "./components/Error/ErrorBoundary";
import VirtualBackground from "./components/ConferenceRoom/virtualBackground_ts/VirtualBackground";
// import ManualPayment from "./components/payment/stripe/PaymentPage/ManualPayment";
// import Home from './components/Home/Home'
const Login = React.lazy(() =>
  componentLoader(() => import("./components/Login/Login"))
);
const Logout = React.lazy(() =>
  componentLoader(() => import("./components/Logout/Logout"))
);
const Registration = React.lazy(() =>
  componentLoader(() => import("./components/Registration/Registration"))
);
const ActivationHash = React.lazy(() =>
  componentLoader(() => import("./components/ActivationHash/ActivationHash"))
);
const Activation = React.lazy(() =>
  componentLoader(() => import("./components/Activation/Activation"))
);
const Recovery = React.lazy(() =>
  componentLoader(() => import("./components/Recovery/Recovery"))
);
const RecoveryHash = React.lazy(() =>
  componentLoader(() => import("./components/RecoveryHash/RecoveryHash"))
);
const RecoveryWithCode = React.lazy(() =>
  componentLoader(() => import("./components/RecoveryHash/RecoveryWithCode"))
);
const Error = React.lazy(() =>
  componentLoader(() => import("./components/Error/Error"))
);
const TalkNow = React.lazy(() =>
  componentLoader(() => import("./components/TalkNow/TalkNow"))
);

const QRCodeGenerator = React.lazy(() =>
  componentLoader(() => import("./components/QRCode/QRCodeGenerator.jsx"))
);

// import Profile from "./components/Profile/Profile";
// import Paypal from "./components/Paypal/Paypal";

const Welcome = React.lazy(() =>
  componentLoader(() => import("./components/welcome/Welcome"))
);
const LandingPage = React.lazy(() =>
  componentLoader(() => import("./components/Landing/LandingPage"))
);
// const Home = React.lazy(() =>
//   componentLoader(() => import("./components/Home/Home"))
// );

// import * as AppInitalizerService from './AppInitializer';

const ParticipantLandingPage = React.lazy(() =>
  componentLoader(() =>
    import("./components/ConferenceRoom/ParticipantLandingPage")
  )
);

// const PrivateRoute = React.lazy(() =>
//   import("./components/common/PrivateRoute")
// );
// const DefaultLayout = React.lazy(() =>
//   componentLoader(() => import("./components/DefaultLayout/DefaultLayout"))
// );
const DefaultLayoutTest = React.lazy(() =>
  componentLoader(() => import("./components/DefaultLayout/DefaultLayoutTest"))
);
// const Feedback = React.lazy(() =>
//   componentLoader(() => import("./components/Feedback/Feedback"))
// );
const Pricing = React.lazy(() =>
  componentLoader(() => import("./components/Pricing/Pricing"))
);
const Payment = React.lazy(() =>
  componentLoader(
    () => import("./components/payment/stripe/PaymentPage/StripeContainer")
    // ()=>import("./components/Feedback/Feedback")
  )
);
const ManualPayment = React.lazy(() =>
  componentLoader(
    () => import("./components/payment/stripe/PaymentPage/ManualPayment")
    // ()=>import("./components/Feedback/Feedback")
  )
);
const Help = React.lazy(() =>
  componentLoader(() => import("./components/Help/Faq"))
);
const Support = React.lazy(() =>
  componentLoader(() => import("./components/Support/Report"))
);
const VideoView = React.lazy(() =>
  componentLoader(() => import("./components/Storage/Recordings/VideoView"))
);
// Conference layout compomemt
// import ConferenceLayout from "./components/ConferenceLayout/ConferenceLayout";

// toast.configure({
//   autoClose: 5000,
//   draggable: false,
// });

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.url = `https://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`;
    this.paypal = {
      clientId: "",
    };
    this.linkTextRef = React.createRef();
    this.browser = null;
    this.props.setNotifications(this.notification);
    this.props.setUrl(this.url);
    this.props.setPaypal(this.paypal);
    axios.defaults.withCredentials = true;
    const browser = Bowser.getParser(window.navigator.userAgent);
    // console.log(browser.parsedResult)
    if (browser.parsedResult.os.name === "iOS") {
      this.browser = "Safari";
    } else {
      this.browser = "Chrome";
    }
    this.isValidBrowser = browser.satisfies({
      // declare browsers per OS
      windows: {
        chrome: ">85",
        firefox: ">80",
        edg: ">80",
      },
      linux: {
        chrome: ">85",
        firefox: ">80",
      },
      iOS: {
        safari: ">11.1",
        chrome: ">90",
      },
      macos: {
        safari: ">10.1",
        chrome: ">85",
      },
      Android: {
        chrome: ">85",
      },
      // per platform (mobile, desktop or tablet)
      // mobile: {
      //   safari: '>=12',
      //   "chrome":">70",
      //   'android browser': '>80'
      // },
    });
  }

  componentDidMount() {
    // AppInitalizerService.initializeSocketConnectionAndListeners(this.props,toast,this.props.history);

    axios.interceptors.response.use(undefined, (error) => {
      if (error.response.status === 401) {
        this.props.setAuth(false);
        // this.notification({
        //   type: "danger",
        //   title: "Failed!",
        //   message: "Not logged in!",
        // });
        return Promise.reject(error);
      }
    });
  }

  copyToClipboard = (e) => {
    this.linkTextRef.current.select();
    document.execCommand("copy");

    this.handleClick();
  };

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  notification(options) {
    let { type, title, message } = options;
    if (type === "danger") {
      type = "warn";
    } else if (type === "success") {
      type = "info";
    }
    toast[type](
      <div>
        <b>{title}</b>
        <br />
        {message}
      </div>
    );
  }

  render() {
    // console.log(this.props.browser);
    // console.log(this.isValidBrowser);
    // console.log(window.location)
    return (
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={theme}
            // theme={responsiveFontSizes(
            //   createTheme({
            //     typography: {
            //       fontFamily: [
            //         "-apple-system",
            //         "BlinkMacSystemFont",
            //         '"Segoe UI"',
            //         "Roboto",
            //         '"Helvetica Neue"',
            //         "Arial",
            //         "sans-serif",
            //         '"Apple Color Emoji"',
            //         '"Segoe UI Emoji"',
            //         '"Segoe UI Symbol"',
            //       ].join(","),
            //     },

            //     palette: {
            //       mode: this.props.theme,
            //       background: { paper: "#fff", default: "#3f51b5" },
            //     },
            //   })
            // )}
          >
            <CssBaseline />
            {this.isValidBrowser ? (
              <ErrorBoundary>
                <div className={styles.app}>
                  <Suspense fallback={<Loading />}>
                    {/* <Navigation /> */}
                    <Routes>
                      <Route path="/" element={<LandingPage />} />
                      <Route path="/vb" element={<VirtualBackground />} />
                      <Route path="/qr-code-generator" element={<QRCodeGenerator />} />
                      <Route path="/faq" element={<Help />} />
                      {/* <Route path="/support" element={<Support />} /> */}
                      <Route path="/landingOld" element={<Welcome />} />
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/meetnow/:uuid/:siteSetting/:name"
                        element={<TalkNow />}
                      />
                      <Route path="/video/:uuid/:id" element={<VideoView />} />
                      <Route path="/login/:id" element={<Login />} />
                      <Route path="/logout" element={<Logout />} />
                      <Route path="/activation" element={<Activation />} />
                      <Route path="/registration" element={<Registration />} />
                      <Route
                        path="/activation/:hash"
                        element={<ActivationHash />}
                      />
                      <Route path="/recovery" element={<Recovery />} />
                      <Route
                        path="/recovery/:hash"
                        element={<RecoveryHash />}
                      />
                      <Route path="/recoveryc" element={<RecoveryWithCode />} />

                      <Route
                        path="/cf/:uuid/:id/:type"
                        element={<ParticipantLandingPage />}
                        exact
                      />
                      <Route path="/feedback/:orgid" element={<Feedback />} />
                      <Route
                        path="/payment"
                        element={<PrivateRoute component={ManualPayment} />}
                      />
                      <Route
                        path="/dashboard/*"
                        element={<PrivateRoute component={DefaultLayout} />}
                      />

                      <Route
                        path="/layout-test"
                        element={<PrivateRoute component={DefaultLayoutTest} />}
                      />

                      {/* <PrivateRoute
                      path="/payment"
                      component={Payment}
                      authenticated={this.props.authenticated}
                    /> */}
                      {/*  */}
                      {/* <Route
                   path="/conference-layout"
                   component={ConferenceLayout}
                   exact
                 /> */}
                      {/* <Route path="/" element={<PrivateRoute />}>
                      <Route path="/" element={<DefaultLayout />}></Route>
                      <Route path="/payment" element={<Payment />} />
                    </Route> */}
                      {/* <PrivateRoute
                      path="/"
                      component={DefaultLayout}
                      authenticated={this.props.authenticated}
                    /> */}
                      {/* <PrivateRoute
                      path="/payment"
                      component={Payment}
                      authenticated={this.props.authenticated}
                    /> */}
                      <Route path="*" element={<Error />} />
                    </Routes>
                  </Suspense>
                </div>
              </ErrorBoundary>
            ) : (
              //  <div className={styles.appOtherBrowsers}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" gutterBottom component={"p"}>
                  {`This app works great with ${this.browser}! How about downloading ${this.browser} if you don't have yet!`}
                </Typography>

                {this.browser === "Chrome" ? (
                  //  <div>
                  <h4>
                    Here is{" "}
                    <a
                      href="https://www.google.com/chrome"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "green" }}
                    >
                      the link
                    </a>{" "}
                    to download the latest version of Chrome according to your
                    OS. Click below to copy the link and open in another
                    browser!
                  </h4>
                ) : null}
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    // error
                    id="landingPageLinkCopy"
                    disabled
                    label="click to copy the link"
                    fullWidth
                    margin="normal"
                    value={window.location.href}
                    // helperText="Incorrect entry."
                    variant="outlined"
                    // name="roomName"
                    // onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={this.copyToClipboard}
                        >
                          <FileCopyOutlinedIcon
                            style={{ color: "orange", cursor: "pointer" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* </div> */}
                </Grid>
                <UserFeedbackSnackbar
                  severity={"success"}
                  autoHideDuration={6000}
                  handleClose={this.handleClose}
                  open={this.state.open}
                  message={`Link copied! Open this link in the ${this.browser} browser. `}
                />

                <div style={{ position: "absolute", left: "-9999px" }}>
                  <TextField
                    // error
                    // id="filled-error-helper-text"
                    label="the link"
                    fullWidth
                    margin="normal"
                    value={window.location.href}
                    variant="outlined"
                    name="myUniqueLink"
                    inputRef={this.linkTextRef}
                    // onChange={handleChange}
                  />
                </div>
              </Grid>
            )}
          </ThemeProvider>
        </StyledEngineProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.connection.authenticated,
    theme: state.connection.theme,
    kurento: state.kurento,
    kurentoStreaming: state.kurentoStreaming,
    notification: state.connection.notification,
    browser: state.connection.browser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUrl: (url) => {
      dispatch(setUrl(url));
    },
    setAuth: (authenticated) => {
      dispatch(setAuth(authenticated));
    },
    setNotifications: (notifications) => {
      dispatch(setNotifications(notifications));
    },
    setPaypal: (paypal) => {
      dispatch(setPaypal(paypal));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
