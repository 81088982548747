export const MSG_SENDER_TYPE_PRESENTER = "participant";
export const MSG_SENDER_TYPE_VIEWER = "viewer";
export const MSG_SENDER_TYPE_EVERYBODY = "everybody";
export const MSG_SENDER_TYPE_MODERATOR = "moderator";
export const MSG_TYPE_PRIVATE = "private";
export const MSG_TYPE_PUBLIC = "public";
export const MSG_DIRECTION_SEND = "send";
export const MSG_DIRECTION_RECV = "recv";
export const STREAM_TYPE_CAM_AUDIO = "cam-audio";
export const STREAM_TYPE_CAM_VIDEO = "cam-video";
export const STREAM_TYPE_SCREEN_AUDIO = "screen-audio";
export const STREAM_TYPE_SCREEN_VIDEO = "screen-video";
export const ROOM_STATUS_ENUM_FREE = "free";
export const ROOM_STATUS_ENUM_OCUPIED = "occupied";
export const ROOM_LEAVE_ONLY = "leaveRoomNew";
export const ROOM_LEAVE_AND_CLOSE = "leaveAndCloseRoom";
export const TOTAL_RECORDING_SPACE_AVAILABLE = 256;
export const USER_TYPE_ADMIN = "admin";
export const USER_TYPE_MODERATOR = "moderator";
export const USER_TYPE_PRESENTER = "presenter";
export const PARTICIPANT = "participant";
export const USER_TYPE_REGULAR = "r";
export const USER_TYPE_BOT = "b";
export const ROOMTYPE_P2P = "p2p";
export const ROOMTYPE_CONFERENCING = "conferencing";
export const ROOMTYPE_EVENT = "event";
export const ROOM_CLOSE_TYPE_CLIENT = "client";
export const CLOUD_AWS = "aws";
export const CLOUD_DO = "do";
export const STAGE = "Stage";
export const BACK_STAGE = "Back-Stage";
export const RECORD = "record";
export const RTMPSTREAM = "rtmpStream";
export const RTMPSTREAMANDRECORD = "rtmpStreamAndRecord";

// url type
export const URL_GET = "get";
export const URL_POST = "post";
export const URL_PUT = "put";
export const URL_DELETE = "delete";
// backend url
export const URL_API = "/api/";
export const URL_USER = URL_API + "user";
export const URL_SITE_SETTING = URL_API + "siteSetting";
export const URL_ROOM_SETTING = URL_API + "roomSetting";
export const URL_ROOM_SETTING_BY_QUERY = URL_ROOM_SETTING + "/fetchByQuery";
export const URL_PAYMENT = URL_API + "payment";
export const URL_CALL_STATS = URL_API + "callStats";
export const URL_CALL_GET_PARTICIPANT_DETAILS =
  URL_CALL_STATS + "/getparticipantDetails";
export const URL_CALL_SAVE_FEEDBACK = URL_CALL_STATS + "/savefeedback";
export const URL_FETCH_RECORDINGS = URL_API + "video";
export const URL_DELETE_RECORDING = URL_FETCH_RECORDINGS + "/delete";
export const URL_GENERATE_AUTH_TOKEN = URL_USER + "/createtoken";
export const URL_CLIENT_CREDENTIAL = URL_USER + "/clientCredential";
export const URL_STIPE_PAYMENT_DETAIL =
  URL_API + "/stripe/checkoutSubscription/checkout-session?sessionId=";
export const URL_STIPE_PAYMENT_CANCEL =
  URL_API + "/stripe/checkoutSubscription/cancel";
export const URL_STRIPE_PAYMENT_CHARGE = URL_API + "/stripe/charge";
export const URL_STRIPE_PAYMENT_SUCCESS = URL_API + "/stripe/success";
export const URL_LOADBALANCER = URL_API + "loadBalancer";
export const URL_PROSPECTS = URL_API + "prospects";
export const URL_SUPPORT = URL_API + "support";
