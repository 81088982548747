import React from "react";
import fscreen from "fscreen";
import clsx from "clsx";
// material ui
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setFullScreen } from "../../actions/connectionActions";

const useStyles = makeStyles((theme) => ({
  styledMenu: {
    "& div.MuiPaper-root": {
      backgroundColor: "#FFFFFF",
      paddingTop: 0,
      "& ul.MuiList-root": {
        paddingTop: 0,
        paddingBottom: 0,
        "& :last-child": {
          marginBottom: 0,
        },
      },
    },
    marginTop: "5px",
  },
  styledMenuItem: {
    // backgroundColor: "rgba(63, 81, 181, 0.1)",
    marginBottom: "2px",
    height: "43px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "8px",
  },
})((props) => (
  <Menu
    elevation={0}
    // getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // "& .MuiListItemIcon-root": {
    //   minWidth: "25px",
    //   marginRight: "12px",
    // },
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white,
    //   },
    // },
  },
}))(MenuItem);

export default function CustomizedMenus(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const fullScreen = useSelector((state) => state.connection.fullScreen);

  const handleClick = (event) => {
    if (fullScreen) {
      if (fscreen.fullscreenElement) {
        fscreen.exitFullscreen();
      }
      dispatch(setFullScreen(false));
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={props.style ? props.style : null}
      // onMouseEnter={(e) => {
      //   props.hover && handleClick(e);
      // }}
    >
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        // color={props.iconColor?props.iconColor:}
        onClick={(e) => {
          handleClick(e);
        }}
        // onMouseLeave={(e) => {
        //   console.log("leavinggggggggggggggggggggggggggggggggg");
        //   handleClose(e);
        // }}
        // size="large"
        style={props.iconButtonStyle ? props.iconButtonStyle : null}
        size="large"
      >
        {props.icon}
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.styledMenu}
      >
        {props.menuItems && props.menuItems.length > 0
          ? props.menuItems.map((menuItem, key) =>
              menuItem ? (
                <StyledMenuItem
                  key={key}
                  onClick={() => {
                    menuItem.function();
                    handleClose();
                  }}
                  style={
                    menuItem.borderTop
                      ? { borderTop: "1px solid rgba(0, 0, 0, 0.12)" }
                      : null
                  }
                  className={classes.styledMenuItem}
                >
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText
                    primary={menuItem.primaryText}
                    style={
                      menuItem.primaryTextColor
                        ? { color: menuItem.primaryTextColor }
                        : null
                    }
                  />
                </StyledMenuItem>
              ) : null
            )
          : null}
      </StyledMenu>
    </div>
  );
}
