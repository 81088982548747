// import { socketConf } from "../utils/socket";
// import * as config from '../utils/config';
// import * as mediasoup from 'mediasoup-client';

import {
  PARTICIPANT_REGISTERED,
  PARTICIPANT_REGISTERED_NEW,
  SET_NAME_ROOMNAME_CONF,
  UPDATE_CID,
  GET_ERRORS,
  UPDATE_SEND_TRANSPORT,
  UPDATE_MEDIA_PRODUCERS,
  UPDATE_RECV_TRANSPORT,
  UPDATE_CONSUMERS,
  UPDATE_JOIN_STATUS,
  LEAVE_AND_RESET,
  UPDATE_STATS,
  REMOVE_PEER,
  UPDATE_SCREEN_MEDIA_PRODUCERS,
  REMOVE_PEER_MEDIA,
  UPDATE_ROOM_CAPABILITIES,
  UPDATE_SOCKET,
  UPDATE_SHOCKET_SHOULD_RECONNECT,
  SET_TSA,
  CLOSE_ROOM_BY_MODERATOR,
  SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_SECONDARY_CALL_STATUS,
  UPDATE_CURRENT_CAMERA_LABEL,
  UPDATE_VIDEO_STATUS,
  UPDATE_AUDIO_STATUS,
  UPDATE_VIDEO_PRODUCER,
  UPDATE_VIDEO_PRIVACY,
  UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_TO_ME,
  UPDATE_PARTICIPANT_DETAILS,
  UPDATE_CHAT_MESSAGES,
  CLOSE_ROOM_LEAVE_ALERT,
  UPDATE_RIGHT_BAR_COMPONENT,
  UPDATE_FULLSCREEN,
  UPDATE_FULLSCREEN_STATUS,
  UPDATE_FULLSCREEN_DRAWER_STATUS,
  UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_DETAILS,
  UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG,
  UPDATE_AUTHENTICATION_REQUESTERS,
  UPDATE_USER_FEEDBACK_MESSAGES,
  UPDATE_USER_ERRORS,
  UPDATE_RESTART_ICE_IN_PROGRESS_STATUS,
  UPDATE_CYCLE_CAMERA_BUSY,
  UPDATE_CALL_START_TIME,
  UPDATE_HAND_RAISE,
  UPDATE_CURRENT_MIC_LABEL,
  UPDATE_CURRENT_SPEAKER_LABEL,
  RESET_PARTICIPANT_SENDING_CAPABILITIES,
  CREATE_BREAKOUT_ROOMS,
  CREATE_GROUPs_FOR_BREAKOUT_ROOMS,
  UPDATE_BREAKOUT_ROOM_STATUS,
  UPDATE_BREAKOUT_ROOM_JOIN_STATUS,
  UPDATE_SHOW_BREAKOUT_ROOM_OPTION,
  // hover menues
  LANDING_PAGE_MIC_HOVER_MENU,
  LANDING_PAGE_PEOPLE_HOVER_MENU,
  LANDING_PAGE_CAMERA_HOVER_MENU,
  UPDATE_SPOTLIGHT,
  UPGRADE_PARTICIPANT_TYPE,
  CHANGE_BREAKOUT_SECTION_GROUPS,
  CHANGE_CONF_GENERAL_SETTINGS,
  CHANGE_CONF_AUTH_SETTINGS,
  CHANGE_CONF_PRESENTER_SETTINGS,
  CHANGE_CONF_PARTICIPANT_SETTINGS,
  CHANGE_CONF_CHAT_SETTINGS,
  SET_GENERAL_ALERT,
  SET_UPGRADE_REQUESTED,
  UPDATE_RECORDING_STATUS,
  UPDATE_BR_RECORDING_STATUS,
  SET_LOADBALANCER_DETAILS,
  UPDATE_MEDIA_LABELS,
  UPDATE_UUID,
  UPDATE_STAGE_MODE,
  UPDATE_BR_DESCRIPTIONS,
  UPDATE_NOOFVIEWERS,
  SET_VB_STATUS,
  SET_VB_ITEMS_STATUS,
  SET_VB_DETAILS,
  SET_ROOM_LOCK_STATUS,
  UPDATE_LIVE_STREAMING_STATUS,
  UPDATE_BR_STREAMING_STATUS,
} from "./types";

export const presenterRegistered = (message) => {
  //console.log("presenter registered")

  return {
    type: PARTICIPANT_REGISTERED,
    payload: message.presenterDetails,
  };
};

export const setNameAndRoomName = (payload) => (dispatch) => {
  dispatch(setNameAndRoomNameAction(payload));
};

export const setNameAndRoomNameAction = (payload) => {
  return {
    type: SET_NAME_ROOMNAME_CONF,
    payload: payload,
  };
};

// export const sendMessage = (message) => {
//     let jsonMessage = JSON.stringify(message);
//     // console.log('Sending message: ' + jsonMessage);
//     socketConf.send(jsonMessage);
// };

export const newPeerJoined =
  async (device, routerRtpCapabilities) => async (dispatch) => {
    try {
      if (!device.loaded) {
        await device.load(routerRtpCapabilities);
      }

      dispatch(setRoomJoinStatus(true));
    } catch (err) {
      console.error(err);
      return;
    }
  };

export const setRoomJoinStatus = (joinStatus) => {
  return {
    type: PARTICIPANT_REGISTERED_NEW,
    payload: joinStatus,
  };
};

export const updateSocketShouldReconnect = (payload) => (dispatch) => {
  dispatch(updateSocketShouldReconnectAction(payload));
};

export const updateSocketShouldReconnectAction = (payload) => {
  return {
    type: UPDATE_SHOCKET_SHOULD_RECONNECT,
    payload: payload,
  };
};

export const updateConfSendTransport = (transport) => (dispatch) => {
  dispatch(updateSendTransport(transport));
};

export const updateSendTransport = (transport) => {
  //console.log("presenter registered")

  return {
    type: UPDATE_SEND_TRANSPORT,
    payload: transport,
  };
};

export const updateConfMediaProducers =
  (videoProducer, audioProducer) => (dispatch) => {
    dispatch(updateMediaProducers(videoProducer, audioProducer));
  };

export const updateMediaProducers = (videoProducer, audioProducer) => {
  return {
    type: UPDATE_MEDIA_PRODUCERS,
    payload: {
      videoProducer: videoProducer,
      audioProducer: audioProducer,
    },
  };
};

export const updateConfRecvTransport = (transport) => (dispatch) => {
  dispatch(updateRecvTransport(transport));
};

export const updateRecvTransport = (transport) => {
  //console.log("presenter registered")

  return {
    type: UPDATE_RECV_TRANSPORT,
    payload: transport,
  };
};

export const updateConfConsumers = (consumer) => (dispatch) => {
  dispatch(updateConsumers(consumer));
};

export const updateConsumers = (consumer) => {
  //console.log("presenter registered")

  return {
    type: UPDATE_CONSUMERS,
    payload: consumer,
  };
};

export const updateRoomJoinStatus = (status) => (dispatch) => {
  dispatch(updateJoinStatus(status));
};

export const updateJoinStatus = (status) => {
  //console.log("presenter registered")

  return {
    type: UPDATE_JOIN_STATUS,
    payload: status,
  };
};

export const leaveRoomAndReset = () => (dispatch) => {
  dispatch(leaveAndReset());
};

export const leaveAndReset = () => {
  //console.log("presenter registered")

  return {
    type: LEAVE_AND_RESET,
    payload: null,
  };
};

export const updatePeerStats = (peers, activeSpeaker) => (dispatch) => {
  dispatch(updateStats(peers, activeSpeaker));
};

export const updateStats = (peers, activeSpeaker) => {
  //console.log("presenter registered")

  return {
    type: UPDATE_STATS,
    payload: {
      peers: peers,
      activeSpeaker: activeSpeaker,
    },
  };
};

export const removeConsumerPeer = (peerId) => (dispatch) => {
  dispatch(removePeer(peerId));
};

export const removePeer = (peerId) => {
  //console.log("presenter registered")

  return {
    type: REMOVE_PEER,
    payload: peerId,
  };
};

export const updateConfScreenMediaProducers =
  (videoProducer, audioProducer) => (dispatch) => {
    dispatch(updateScreenMediaProducers(videoProducer, audioProducer));
  };

export const updateScreenMediaProducers = (videoProducer, audioProducer) => {
  return {
    type: UPDATE_SCREEN_MEDIA_PRODUCERS,
    payload: {
      videoProducer: videoProducer,
      audioProducer: audioProducer,
    },
  };
};

export const removeConsumerPeerMedia = (peerId, mediaTag) => (dispatch) => {
  dispatch(removePeerMedia(peerId, mediaTag));
};

export const removePeerMedia = (peerId, mediaTag) => {
  //console.log("presenter registered")

  return {
    type: REMOVE_PEER_MEDIA,
    payload: {
      peerId,
      mediaTag,
    },
  };
};
export const updateCId = (cId) => (dispatch) => {
  dispatch(cIdUpdate(cId));
};
export const cIdUpdate = (payload) => {
  return {
    type: UPDATE_CID,
    payload: payload,
  };
};

export const updateRoomCapabilities = (capabilities) => (dispatch) => {
  dispatch(roomCapabilitiesUpdate(capabilities));
};
export const roomCapabilitiesUpdate = (payload) => {
  return {
    type: UPDATE_ROOM_CAPABILITIES,
    payload: payload,
  };
};

export const updateSocket = (socket) => (dispatch) => {
  // console.log('going to set socket address')
  dispatch(socketUpdate(socket));
};
export const socketUpdate = (payload) => {
  return {
    type: UPDATE_SOCKET,
    payload: payload,
  };
};

export const updateTotalSpaceAvailable = (totalSpaceAvailable) => {
  return {
    type: SET_TSA,
    payload: totalSpaceAvailable,
  };
};

export const updateStoreOnRoomClosure = () => (dispatch) => {
  dispatch(updateOnRoomClosure());
};

export const updateOnRoomClosure = () => {
  //console.log("presenter registered")

  return {
    type: CLOSE_ROOM_BY_MODERATOR,
    payload: null,
  };
};
export const setSecondaryCallStatus = (status) => (dispatch) => {
  dispatch(updateScreenShareStatus(status));
};
export const updateScreenShareStatus = (payload) => {
  return {
    type: SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_SECONDARY_CALL_STATUS,
    payload: payload,
  };
};

export const updateCurrentCamera = (cameraLabel) => (dispatch) => {
  dispatch(updateCurrentCameraLabel(cameraLabel));
};

export const updateCurrentCameraLabel = (payload) => {
  return {
    type: UPDATE_CURRENT_CAMERA_LABEL,
    payload: payload,
  };
};

export const updateConfVideoStatus = (videoStatus) => (dispatch) => {
  dispatch(updateVideoStatus(videoStatus));
};

export const updateVideoStatus = (videoStatus) => {
  return {
    type: UPDATE_VIDEO_STATUS,
    payload: {
      videoStatus: videoStatus,
    },
  };
};
export const updateConfAudioStatus = (audioStatus) => (dispatch) => {
  dispatch(updateAudioStatus(audioStatus));
};

export const updateAudioStatus = (audioStatus) => {
  return {
    type: UPDATE_AUDIO_STATUS,
    payload: {
      audioStatus: audioStatus,
    },
  };
};
export const updateConfVideoProducer = (videoProducer) => (dispatch) => {
  dispatch(updateVideoProducer(videoProducer));
};

export const updateVideoProducer = (videoProducer) => {
  return {
    type: UPDATE_VIDEO_PRODUCER,
    payload: videoProducer,
  };
};

export const updateConfVideoPrivacy = (status) => (dispatch) => {
  dispatch(updateVideoPrivacy(status));
};

export const updateVideoPrivacy = (status) => {
  return {
    type: UPDATE_VIDEO_PRIVACY,
    payload: status,
  };
};

export const updateShowUserAuthenticationDialogToMe = (status) => {
  return {
    type: UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_TO_ME,
    payload: status,
  };
};

export const addParticipant = (participants) => {
  return {
    type: UPDATE_PARTICIPANT_DETAILS,
    payload: participants,
  };
};

export const updateChatMessages = (chatMessages) => {
  return {
    type: UPDATE_CHAT_MESSAGES,
    payload: chatMessages,
  };
};

export const closeRoomLeaveAlert = (status) => {
  return {
    type: CLOSE_ROOM_LEAVE_ALERT,
    payload: status,
  };
};

export const updateRightBarComponent = (status, tabValue) => {
  return {
    type: UPDATE_RIGHT_BAR_COMPONENT,
    payload: {
      status,
      tabValue,
    },
  };
};

export const updateFullScreen = (peer, status) => {
  return {
    type: UPDATE_FULLSCREEN,
    payload: {
      peer,
      status,
    },
  };
};

// export const updateFullScreenStatus = (status) =>{
//     return {
//         type: UPDATE_FULLSCREEN_STATUS,
//         payload: status,
//     }
// }

export const updateFullScreenDrawerStatus = (status) => {
  return {
    type: UPDATE_FULLSCREEN_DRAWER_STATUS,
    payload: status,
  };
};

export const updateShowUserAuthenticationDialogDetails = (details) => {
  return {
    type: UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_DETAILS,
    payload: details,
  };
};

export const updateShowUserAuthenticationDialog = (status) => {
  return {
    type: UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG,
    payload: status,
  };
};

export const updateAuthenticationRequesters = (status) => {
  return {
    type: UPDATE_AUTHENTICATION_REQUESTERS,
    payload: status,
  };
};

export const updateUserFeedbackMessages = (status) => {
  return {
    type: UPDATE_USER_FEEDBACK_MESSAGES,
    payload: status,
  };
};

export const updateUserErrors = (errors, status) => {
  return {
    type: UPDATE_USER_ERRORS,
    payload: {
      errors,
      status,
    },
  };
};

export const updateRestartIceInProgressStatus = (status) => {
  return {
    type: UPDATE_RESTART_ICE_IN_PROGRESS_STATUS,
    payload: status,
  };
};

export const updateCycleCameraBusy = (status) => {
  return {
    type: UPDATE_CYCLE_CAMERA_BUSY,
    payload: status,
  };
};

export const updateCallStartTime = (startTime) => {
  return {
    type: UPDATE_CALL_START_TIME,
    payload: startTime,
  };
};

export const updateHandRaise = (status) => {
  return {
    type: UPDATE_HAND_RAISE,
    payload: status,
  };
};

export const updateCurrentMicLabel = (payload) => {
  return {
    type: UPDATE_CURRENT_MIC_LABEL,
    payload: payload,
  };
};

export const updateCurrentSpeakerLabel = (payload) => {
  return {
    type: UPDATE_CURRENT_SPEAKER_LABEL,
    payload: payload,
  };
};

export const resetParticipantSendingCapabilities = () => {
  return {
    type: RESET_PARTICIPANT_SENDING_CAPABILITIES,
    payload: null,
  };
};

export const createBreakOutRooms = (breakOutRooms) => {
  return {
    type: CREATE_BREAKOUT_ROOMS,
    payload: breakOutRooms,
  };
};

export const createBreakOutRoomGroups = (groups) => {
  return {
    type: CREATE_GROUPs_FOR_BREAKOUT_ROOMS,
    payload: groups,
  };
};

export const updateBreakOutRoomStatus = (status) => {
  return {
    type: UPDATE_BREAKOUT_ROOM_STATUS,
    payload: status,
  };
};

export const updateBreakOutRoomJoin = (
  groupName,
  status,
  breakOutRoomParticipants
) => {
  return {
    type: UPDATE_BREAKOUT_ROOM_JOIN_STATUS,
    payload: {
      status,
      groupName,
      breakOutRoomParticipants,
    },
  };
};

export const updateShowBreakOutRoomOption = (status) => {
  return {
    type: UPDATE_SHOW_BREAKOUT_ROOM_OPTION,
    payload: status,
  };
};

// for hover menues
// mic menu
export const openLandingPageMicHoverMenu = (status) => {
  return {
    type: LANDING_PAGE_MIC_HOVER_MENU,
    payload: status,
  };
};
// people menu
export const openLandingPagePeopleHoverMenu = (status) => {
  return {
    type: LANDING_PAGE_PEOPLE_HOVER_MENU,
    payload: status,
  };
};
// camera menu
export const openLandingPageCameraHoverMenu = (status) => {
  return {
    type: LANDING_PAGE_CAMERA_HOVER_MENU,
    payload: status,
  };
};

export const updateSpotLight = (peer, status) => {
  return {
    type: UPDATE_SPOTLIGHT,
    payload: {
      peer,
      status,
    },
  };
};
export const updateParticipantType = (type) => {
  return {
    type: UPGRADE_PARTICIPANT_TYPE,
    payload: type,
  };
};
// changing breakeout section goups
export const changeBreakoutSectionGroups = (value) => {
  return {
    type: CHANGE_BREAKOUT_SECTION_GROUPS,
    payload: value,
  };
};

export const updateGeneralSettings = (
  allowScreenShare,
  noOfScreenShare,
  noOfUpgradeRequests
) => {
  return {
    type: CHANGE_CONF_GENERAL_SETTINGS,
    payload: { allowScreenShare, noOfScreenShare, noOfUpgradeRequests },
  };
};

export const updateAuthSettings = (moderatorApproval, passwordRequired) => {
  return {
    type: CHANGE_CONF_AUTH_SETTINGS,
    payload: { moderatorApproval, passwordRequired },
  };
};

export const updatePresenterSettings = (presenterSettings) => {
  return {
    type: CHANGE_CONF_PRESENTER_SETTINGS,
    payload: presenterSettings,
  };
};

export const updateParticipantSettings = (participantSettings) => {
  return {
    type: CHANGE_CONF_PARTICIPANT_SETTINGS,
    payload: participantSettings,
  };
};

export const updatePublicPrivateChat = (
  publicChatStatus,
  privateChatStatus
) => {
  return {
    type: CHANGE_CONF_CHAT_SETTINGS,
    payload: { publicChatStatus, privateChatStatus },
  };
};

export const setGeneralAlert = (generalAlert) => {
  return {
    type: SET_GENERAL_ALERT,
    generalAlert,
  };
};

export const updateUpgradeRequested = (status) => {
  return {
    type: SET_UPGRADE_REQUESTED,
    status,
  };
};

export const updateRecordingStatus = (status) => {
  return {
    type: UPDATE_RECORDING_STATUS,
    status,
  };
};
export const updateBRRecordingStatus = (breakOutRoomRecordings) => {
  return {
    type: UPDATE_BR_RECORDING_STATUS,
    breakOutRoomRecordings,
  };
};

export const setLoadBalancerDetails = (loadBalancerDetails) => {
  return {
    type: SET_LOADBALANCER_DETAILS,
    loadBalancerDetails,
  };
};

export const updateMediaLabels = (details) => {
  return {
    type: UPDATE_MEDIA_LABELS,
    details,
  };
};
export const updateUuid = (uuid) => {
  return {
    type: UPDATE_UUID,
    uuid,
  };
};

export const updateStageMode = (stageMode) => {
  return {
    type: UPDATE_STAGE_MODE,
    stageMode,
  };
};

export const updateBRDescription = (descriptions) => {
  return {
    type: UPDATE_BR_DESCRIPTIONS,
    descriptions,
  };
};

export const updateNoOfViewers = (noOfViewers) => {
  return {
    type: UPDATE_NOOFVIEWERS,
    noOfViewers,
  };
};

export const setVBStatus = (status) => {
  return {
    type: SET_VB_STATUS,
    status,
  };
};

export const setVBItemsStatus = (status) => {
  return {
    type: SET_VB_ITEMS_STATUS,
    status,
  };
};

export const setVBDetails = (details) => {
  return {
    type: SET_VB_DETAILS,
    details,
  };
};

export const setRoomLockStatus = (roomLockStatus) => {
  return {
    type: SET_ROOM_LOCK_STATUS,
    roomLockStatus,
  };
};

export const updateLiveStreamingStatus = (streamingStatus) => {
  return {
    type: UPDATE_LIVE_STREAMING_STATUS,
    streamingStatus,
  };
};

export const updateBRStreamingStatus = (breakOutRoomStreamings) => {
  return {
    type: UPDATE_BR_STREAMING_STATUS,
    breakOutRoomStreamings,
  };
};
