import { defaultBreakOutGroups } from "../utils/breakOutGroups";
// import * as mediasoup from "mediasoup-client";

import {
  PARTICIPANT_REGISTERED,
  PARTICIPANT_REGISTERED_NEW,
  SET_NAME_ROOMNAME_CONF,
  UPDATE_SEND_TRANSPORT,
  UPDATE_MEDIA_PRODUCERS,
  UPDATE_CONSUMERS,
  UPDATE_RECV_TRANSPORT,
  UPDATE_JOIN_STATUS,
  LEAVE_AND_RESET,
  UPDATE_STATS,
  REMOVE_PEER,
  UPDATE_SCREEN_MEDIA_PRODUCERS,
  REMOVE_PEER_MEDIA,
  UPDATE_CID,
  UPDATE_ROOM_CAPABILITIES,
  UPDATE_SOCKET,
  UPDATE_SHOCKET_SHOULD_RECONNECT,
  SET_TSA,
  CLOSE_ROOM_BY_MODERATOR,
  SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_SECONDARY_CALL_STATUS,
  UPDATE_CURRENT_CAMERA_LABEL,
  UPDATE_VIDEO_STATUS,
  UPDATE_AUDIO_STATUS,
  UPDATE_VIDEO_PRODUCER,
  UPDATE_VIDEO_PRIVACY,
  UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_TO_ME,
  UPDATE_PARTICIPANT_DETAILS,
  UPDATE_CHAT_MESSAGES,
  CLOSE_ROOM_LEAVE_ALERT,
  UPDATE_RIGHT_BAR_COMPONENT,
  UPDATE_FULLSCREEN,
  UPDATE_FULLSCREEN_STATUS,
  UPDATE_FULLSCREEN_DRAWER_STATUS,
  UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_DETAILS,
  UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG,
  UPDATE_AUTHENTICATION_REQUESTERS,
  UPDATE_USER_FEEDBACK_MESSAGES,
  UPDATE_USER_ERRORS,
  UPDATE_RESTART_ICE_IN_PROGRESS_STATUS,
  UPDATE_CYCLE_CAMERA_BUSY,
  UPDATE_CALL_START_TIME,
  UPDATE_HAND_RAISE,
  UPDATE_CURRENT_MIC_LABEL,
  UPDATE_CURRENT_SPEAKER_LABEL,
  RESET_PARTICIPANT_SENDING_CAPABILITIES,
  CREATE_BREAKOUT_ROOMS,
  CREATE_GROUPs_FOR_BREAKOUT_ROOMS,
  UPDATE_BREAKOUT_ROOM_STATUS,
  UPDATE_BREAKOUT_ROOM_JOIN_STATUS,
  UPDATE_SHOW_BREAKOUT_ROOM_OPTION,
  // hover menues
  LANDING_PAGE_MIC_HOVER_MENU,
  LANDING_PAGE_PEOPLE_HOVER_MENU,
  LANDING_PAGE_CAMERA_HOVER_MENU,
  UPDATE_SPOTLIGHT,
  UPGRADE_PARTICIPANT_TYPE,
  CHANGE_BREAKOUT_SECTION_GROUPS,
  CHANGE_CONF_GENERAL_SETTINGS,
  CHANGE_CONF_AUTH_SETTINGS,
  CHANGE_CONF_PRESENTER_SETTINGS,
  CHANGE_CONF_PARTICIPANT_SETTINGS,
  CHANGE_CONF_CHAT_SETTINGS,
  SET_GENERAL_ALERT,
  SET_UPGRADE_REQUESTED,
  UPDATE_RECORDING_STATUS,
  UPDATE_BR_RECORDING_STATUS,
  SET_LOADBALANCER_DETAILS,
  UPDATE_MEDIA_LABELS,
  UPDATE_UUID,
  UPDATE_STAGE_MODE,
  UPDATE_BR_DESCRIPTIONS,
  UPDATE_NOOFVIEWERS,
  SET_VB_STATUS,
  SET_VB_ITEMS_STATUS,
  SET_VB_DETAILS,
  SET_ROOM_LOCK_STATUS,
  UPDATE_LIVE_STREAMING_STATUS,
  UPDATE_BR_STREAMING_STATUS,
} from "../actions/types";
import { uuidv4 } from "../utils/uuid";

const initialState = {
  type: null,
  socket: null,
  participants: {},
  participantsSecondaryCall: {},
  ipCameras: {},
  userName: null,
  roomName: null,
  roomDisplayName: null,
  roomType: null,
  camera: null,
  mic: null,
  speaker: null,
  participantType: null,
  audioStatus: false,
  videoStatus: false,
  secondaryCallStatus: "disconnected",
  useOld: false,
  /* mediasoup */
  peerId: uuidv4,
  // device: new mediasoup.Device(),
  joined: undefined,
  socketShouldReconnect: false,
  // localCam: undefined,
  // localScreen: undefined,
  // recvTransport: undefined,
  // sendTransport: undefined,
  // camVideoProducer: undefined,
  // camAudioProducer: undefined,
  // screenVideoProducer: undefined,
  // screenAudioProducer: undefined,
  currentActiveSpeaker: {},
  // consumers: [],
  // pollingInterval: undefined,
  roomId: null,
  cId: null,
  orgId: undefined,
  eco: false,
  ess: false,
  er: false,
  els: false,
  erm: false,
  erp: false,
  ers: false,
  ersu: null,
  ersk: null,
  tsa: 0,
  noOfSS: 2,
  participantPrivacy: false,
  participantVideoPrivacy: false,
  authenticationRequired: false,
  isRoomPassword: false,
  roomClosedByModerator: false,
  videoLabels: [],
  audioLabels: [],
  speakerLabels: [],
  chatMessages: [],
  showUserAuthenticationDialogToMe: false,
  showRightBarComponent: false,
  showRoomLeaveAlert: false,
  tabValue: 0,
  fullScreenStatus: false,
  fullScreen: {},
  drawerOpen: false,
  userAuthenticationDialogDetails: {},
  showUserAuthenticationDialog: false,
  authenticationRequesters: {},
  userFeedbackMessages: {},
  userErrorStatus: false,
  userError: {},
  restartIceInProgress: false,
  cycleCameraBusy: false,
  callStartTime: 0,
  handRaise: false,
  breakOutRooms: { unAssigned: [] },
  breakOutRoomsActive: false,
  breakOutRoomJoined: null,
  breakOutRoomJoinStatus: false,
  breakOutRoomParticipants: {},
  showBreakOutRoomOption: false,
  // hover menues
  micHoverMenu: false, // mic hover menu
  peopleHoverMenu: false, // people hover menu
  cameraHoverMenu: false, // camera hover menu
  //spot Light
  spotLightStatus: false,
  spotLight: {},
  stageMode: {
    stagePeers: [],
    stageStatus: false,
    backStagePeers: [],
    backStageStatus: false,
  },
  presenterSettings: {
    allowPresenter: true,
    allowPresenterRaiseHand: true,
    allowPresenterPublicChat: true,
    allowPresenterPrivateChat: true,
  },
  participantSettings: {
    allowParticipant: true,
    allowParticipantRaiseHand: true,
    allowParticipantPublicChat: true,
    allowParticipantPrivateChat: true,
  },
  chatSettings: {
    publicChatStatus: true,
    privateChatStatus: true,
  },
  generalAlert: [],
  upgradeRequested: {},
  recordingStatus: false,
  breakOutRoomRecordings: {},
  loadBalancer: { activeServers: 0, totalServers: 0 },
  signallingServerUrl: null,
  uuid: null,
  brDescriptions: {},
  noOfUpgradeRequests: 0,
  noOfViewers: 0,
  prospectId: null,
  vb: false,
  vbItems: true,
  vBDetails: { type: "blur", url: undefined },
  roomLockStatus: {
    lockedByModId: null,
    lockedByModName: null,
    status: false,
    peersWaiting: 0,
  },
  liveStreamingStatus: false,
  breakOutRoomStreamings: {},
  // breakout section groups
  breakoutSectionGroups: {
    noOfGroups: 0,
    values: defaultBreakOutGroups(27),
    // values: [
    //   {
    //     value: 0,
    //     label: "00",
    //   },
    //   {
    //     value: 1,
    //     label: "01",
    //   },
    //   {
    //     value: 2,
    //     label: "02",
    //   },
    //   {
    //     value: 3,
    //     label: "03",
    //   },
    //   {
    //     value: 4,
    //     label: "04",
    //   },
    //   {
    //     value: 5,
    //     label: "05",
    //   },
    //   {
    //     value: 6,
    //     label: "06",
    //   },
    //   {
    //     value: 7,
    //     label: "07",
    //   },
    //   {
    //     value: 8,
    //     label: "08",
    //   },
    //   {
    //     value: 9,
    //     label: "09",
    //   },
    //   {
    //     value: 10,
    //     label: "10",
    //   },
    // ],
  },
};
// socket:socketConf(socketAddress),
const confReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAME_ROOMNAME_CONF:
      return {
        ...state,
        type: action.payload.type,
        peerId: action.payload.peerId,
        userName: action.payload.userName,
        roomDisplayName: action.payload.roomDisplayName,
        roomName: action.payload.roomName,
        camera: action.payload.camera,
        mic: action.payload.mic,
        speaker: action.payload.speaker,
        participantType: action.payload.participantType,
        audioStatus: action.payload.audioStatus,
        videoStatus: action.payload.videoStatus,
        useOld: action.payload.useOld,
        roomId: action.payload.roomId,
        orgId: action.payload.orgId,
        eco: action.payload.eco,
        ess: action.payload.ess,
        er: action.payload.er,
        els: action.payload.els,
        erm: action.payload.erm,
        erp: action.payload.erp,
        participantPrivacy: action.payload.participantPrivacy,
        videoLabels: action.payload.videoLabels,
        audioLabels: action.payload.audioLabels,
        speakerLabels: action.payload.speakerLabels,
        roomClosedByModerator: false,
        participantVideoPrivacy: action.payload.participantVideoPrivacy,
        authenticationRequired: action.payload.authenticationRequired,
        isRoomPassword: action.payload.isRoomPassword,
        roomType: action.payload.roomType,
        signallingServerUrl: action.payload.signallingServerUrl,
        uuid: action.payload.uuid,
        noOfUpgradeRequests: action.payload.noOfUpgradeRequests,
        prospectId: action.payload.prospectId,
        vb: action.payload.virtualBackground,
        vbItems: false,
        ers: action.payload.ers,
        ersu: action.payload.ersu,
        ersk: action.payload.ersk,
        // vBDetails: action.payload.vBDetails,
      };

    case PARTICIPANT_REGISTERED_NEW:
      return {
        ...state,
        joined: action.payload,
      };
    case UPDATE_SHOCKET_SHOULD_RECONNECT:
      // console.log("\n\n\n\n\n\ updateSocketShouldReconnect called");
      return {
        ...state,
        socketShouldReconnect: action.payload,
      };
    case UPDATE_SEND_TRANSPORT:
      return {
        ...state,
        sendTransport: action.payload,
      };
    case UPDATE_VIDEO_PRODUCER:
      return {
        ...state,
        camVideoProducer: action.payload,
      };
    case UPDATE_MEDIA_PRODUCERS:
      return {
        ...state,
        camVideoProducer: action.payload.videoProducer,
        camAudioProducer: action.payload.audioProducer,
      };

    case UPDATE_VIDEO_STATUS:
      return {
        ...state,
        videoStatus: action.payload.videoStatus,
      };

    case UPDATE_AUDIO_STATUS:
      return {
        ...state,
        audioStatus: action.payload.audioStatus,
      };

    case UPDATE_RECV_TRANSPORT:
      return {
        ...state,
        recvTransport: action.payload,
      };
    case UPDATE_CONSUMERS:
      let updatedConsumers = [...state.consumers, action.payload];
      return {
        ...state,
        consumers: updatedConsumers,
      };
    case UPDATE_JOIN_STATUS:
      // let updatedConsumers = [...state.consumers,action.payload]
      return {
        ...state,
        joined: action.payload,
      };
    case LEAVE_AND_RESET:
      // let updatedConsumers = [...state.consumers,action.payload]
      return {
        ...state,
        recvTransport: null,
        sendTransport: null,
        camVideoProducer: null,
        camAudioProducer: null,
        screenVideoProducer: null,
        screenAudioProducer: null,
        localCam: null,
        localScreen: null,
        consumers: [],
        joined: false,
        peerId: null,
        participants: {},
        showRoomLeaveAlert: false,
        showUserAuthenticationDialogToMe: false,
        showRightBarComponent: false,
        tabValue: 0,
        fullScreenStatus: false,
        fullScreen: {},
        drawerOpen: false,
        userAuthenticationDialogDetails: {},
        showUserAuthenticationDialog: false,
        authenticationRequesters: {},
        userFeedbackMessages: {},
        chatMessages: [],
        userErrorStatus: false,
        userError: {},
        restartIceInProgress: false,
        cycleCameraBusy: false,
        callStartTime: 0,
        handRaise: false,
        breakOutRooms: { unAssigned: [] },
        breakOutRoomGroups: {},
        breakOutRoomsActive: false,
        breakOutRoomJoined: null,
        breakOutRoomJoinStatus: false,
        breakOutRoomParticipants: {},
        showBreakOutRoomOption: false,
        spotLightStatus: false,
        spotLight: {},
        stageMode: {
          stagePeers: [],
          stageStatus: false,
          backStagePeers: [],
          backStageStatus: false,
        },
        breakoutSectionGroups: {
          ...state.breakoutSectionGroups,
          noOfGroups: 0,
        },
        authenticationRequired: false,
        isRoomPassword: false,
        noOfSS: 2,
        presenterSettings: {
          allowPresenter: true,
          allowPresenterRaiseHand: true,
          allowPresenterPublicChat: true,
          allowPresenterPrivateChat: true,
        },
        participantSettings: {
          allowParticipant: true,
          allowParticipantRaiseHand: true,
          allowParticipantPublicChat: true,
          allowParticipantPrivateChat: true,
        },
        chatSettings: {
          publicChatStatus: true,
          privateChatStatus: true,
        },
        generalAlert: [],
        upgradeRequested: {},
        recordingStatus: false,
        type: null,
        breakOutRoomRecordings: {},
        roomType: null,
        signallingServerUrl: null,
        uuid: null,
        brDescriptions: {},
        roomDisplayName: null,
        noOfUpgradeRequests: 0,
        currentActiveSpeaker: {},
        noOfViewers: 0,
        prospectId: null,
        secondaryCallStatus: "disconnected",
        vb: false,
        vbItems: true,
        vBDetails: { type: "blur", url: undefined },
        roomLockStatus: {
          lockedByModId: null,
          lockedByModName: null,
          status: false,
          peersWaiting: 0,
        },
        liveStreamingStatus: false,
        breakOutRoomStreamings: {},
        ers: false,
        ersu: null,
        ersk: null,

        // hover menues
        // micHoverMenu: false, // mic hover menu
        // peopleHoverMenu: false, // people hover menu
        // cameraHoverMenu: false, // camera hover menu
      };
    case UPDATE_STATS:
      return {
        ...state,
        currentActiveSpeaker: action.payload.activeSpeaker,
      };
    case REMOVE_PEER:
      let peersAfterRemoval = state.consumers.filter(
        (c) => c.appData.peerId !== action.payload
      );
      return {
        ...state,
        consumers: peersAfterRemoval,
      };
    case UPDATE_SCREEN_MEDIA_PRODUCERS:
      return {
        ...state,
        screenVideoProducer: action.payload.videoProducer,
        screenAudioProducer: action.payload.audioProducer,
      };
    case REMOVE_PEER_MEDIA:
      let peersAfterRemovalWithMediaTag = state.consumers.filter((c) => {
        if (
          c.appData.peerId === action.payload.peerId &&
          c.appData.mediaTag === action.payload.mediaTag
        )
          return false;

        return true;
      });
      //    console.log('consumers after removal',peersAfterRemovalWithMediaTag);
      return {
        ...state,
        consumers: peersAfterRemovalWithMediaTag,
      };

    case UPDATE_CID:
      return {
        ...state,
        cId: action.payload,
      };
    case UPDATE_ROOM_CAPABILITIES:
      return {
        ...state,
        eco: action.payload.chatOptionStatus,
        ess: action.payload.screenShareStatus,
        er: action.payload.recordingStatus,
        els: action.payload.liveStreamingStatus,
      };
    case UPDATE_SOCKET:
      // console.log('updated socker \n\n', action.payload);
      return {
        ...state,
        socket: action.payload,
      };
    case SET_TSA:
      return {
        ...state,
        tsa: action.payload,
      };
    case CLOSE_ROOM_BY_MODERATOR:
      return {
        ...state,
        roomClosedByModerator: true,
      };
    case SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_SECONDARY_CALL_STATUS:
      return {
        ...state,
        secondaryCallStatus: action.payload,
      };
    case UPDATE_CURRENT_CAMERA_LABEL:
      return {
        ...state,
        camera: action.payload,
      };
    case UPDATE_VIDEO_PRIVACY:
      return {
        ...state,
        participantVideoPrivacy: action.payload,
      };
    case UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_TO_ME:
      return {
        ...state,
        showUserAuthenticationDialogToMe: action.payload,
      };
    case UPDATE_PARTICIPANT_DETAILS:
      return {
        ...state,
        participants: action.payload,
      };
    case UPDATE_CHAT_MESSAGES:
      return {
        ...state,
        chatMessages: action.payload,
      };
    case CLOSE_ROOM_LEAVE_ALERT:
      return {
        ...state,
        showRoomLeaveAlert: action.payload,
      };
    case UPDATE_RIGHT_BAR_COMPONENT:
      return {
        ...state,
        showRightBarComponent: action.payload.status,
        tabValue: action.payload.tabValue,
      };
    case UPDATE_FULLSCREEN:
      return {
        ...state,
        fullScreen: action.payload.peer,
        fullScreenStatus: action.payload.status,
      };
    case UPDATE_FULLSCREEN_STATUS:
      return {
        ...state,
        fullScreenStatus: action.payload,
      };
    case UPDATE_FULLSCREEN_DRAWER_STATUS:
      return {
        ...state,
        drawerOpen: action.payload,
      };

    case UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_DETAILS:
      return {
        ...state,
        userAuthenticationDialogDetails: action.payload,
      };

    case UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG:
      return {
        ...state,
        showUserAuthenticationDialog: action.payload,
      };
    case UPDATE_AUTHENTICATION_REQUESTERS:
      return {
        ...state,
        authenticationRequesters: action.payload,
      };
    case UPDATE_USER_FEEDBACK_MESSAGES:
      return {
        ...state,
        userFeedbackMessages: action.payload,
      };
    case UPDATE_USER_ERRORS:
      return {
        ...state,
        userErrorStatus: action.payload.status,
        userError: action.payload.errors,
      };
    case UPDATE_RESTART_ICE_IN_PROGRESS_STATUS:
      return {
        ...state,
        restartIceInProgress: action.payload,
      };
    case UPDATE_CYCLE_CAMERA_BUSY:
      return {
        ...state,
        cycleCameraBusy: action.payload,
      };
    case UPDATE_CALL_START_TIME:
      return {
        ...state,
        callStartTime: action.payload,
      };
    case UPDATE_HAND_RAISE:
      return {
        ...state,
        handRaise: action.payload,
      };
    case UPDATE_CURRENT_MIC_LABEL:
      return {
        ...state,
        mic: action.payload,
      };
    case UPDATE_CURRENT_SPEAKER_LABEL:
      return {
        ...state,
        speaker: action.payload,
      };
    case RESET_PARTICIPANT_SENDING_CAPABILITIES:
      return {
        ...state,
        sendTransport: null,
        camAudioProducer: null,
        camVideoProducer: null,
        screenAudioProducer: null,
        screenVideoProducer: null,
      };
    case CREATE_BREAKOUT_ROOMS:
      return {
        ...state,
        breakOutRooms: action.payload,
      };
    case CREATE_GROUPs_FOR_BREAKOUT_ROOMS:
      return {
        ...state,
        breakOutRoomGroups: action.payload,
      };
    case UPDATE_BREAKOUT_ROOM_STATUS:
      return {
        ...state,
        breakOutRoomsActive: action.payload,
      };
    case UPDATE_BREAKOUT_ROOM_JOIN_STATUS:
      return {
        ...state,
        breakOutRoomJoined: action.payload.groupName,
        breakOutRoomJoinStatus: action.payload.status,
        breakOutRoomParticipants: action.payload.breakOutRoomParticipants,
      };
    case UPDATE_SHOW_BREAKOUT_ROOM_OPTION:
      //   console.log("b4 updating status showbreakoutroom option", action.payload);
      return {
        ...state,
        showBreakOutRoomOption: action.payload,
      };

    // for hover menus
    case LANDING_PAGE_MIC_HOVER_MENU: {
      return {
        ...state,
        micHoverMenu: action.payload,
      };
    }
    case LANDING_PAGE_PEOPLE_HOVER_MENU: {
      return {
        ...state,
        peopleHoverMenu: action.payload,
      };
    }
    case LANDING_PAGE_CAMERA_HOVER_MENU: {
      return {
        ...state,
        cameraHoverMenu: action.payload,
      };
    }
    case UPDATE_SPOTLIGHT:
      return {
        ...state,
        spotLight: action.payload.peer,
        spotLightStatus: action.payload.status,
      };
    case UPGRADE_PARTICIPANT_TYPE: {
      return {
        ...state,
        participantType: action.payload,
      };
    }
    case CHANGE_BREAKOUT_SECTION_GROUPS: {
      return {
        ...state,
        breakoutSectionGroups: {
          ...state.breakoutSectionGroups,
          noOfGroups: action.payload,
        },
      };
    }
    case CHANGE_CONF_GENERAL_SETTINGS:
      return {
        ...state,
        ess: action.payload.allowScreenShare,
        noOfSS: action.payload.noOfScreenShare,
        noOfUpgradeRequests: action.payload.noOfUpgradeRequests,
      };
    case CHANGE_CONF_AUTH_SETTINGS:
      return {
        ...state,
        authenticationRequired: action.payload.moderatorApproval,
        isRoomPassword: action.payload.passwordRequired,
      };
    case CHANGE_CONF_PRESENTER_SETTINGS:
      return {
        ...state,
        presenterSettings: action.payload,
      };
    case CHANGE_CONF_PARTICIPANT_SETTINGS:
      return {
        ...state,
        participantSettings: action.payload,
      };
    case CHANGE_CONF_CHAT_SETTINGS:
      return {
        ...state,
        chatSettings: action.payload,
      };
    case SET_GENERAL_ALERT:
      return {
        ...state,
        generalAlert: action.generalAlert,
      };
    case SET_UPGRADE_REQUESTED:
      return {
        ...state,
        upgradeRequested: action.status,
      };
    case UPDATE_RECORDING_STATUS:
      return {
        ...state,
        recordingStatus: action.status,
      };
    case UPDATE_BR_RECORDING_STATUS:
      return {
        ...state,
        breakOutRoomRecordings: action.breakOutRoomRecordings,
      };
    case SET_LOADBALANCER_DETAILS:
      return {
        ...state,
        loadBalancer: action.loadBalancerDetails,
      };
    case UPDATE_MEDIA_LABELS:
      return {
        ...state,
        videoLabels: action.details.allDevices.videoDevices,
        audioLabels: action.details.allDevices.audioDevices,
        speakerLabels: action.details.allDevices.audioDevicesOutput,
        camera: action.details.videoLabel,
        mic: action.details.audioLabel,
        speaker: action.details.speakerLabel,
      };
    case UPDATE_UUID:
      return {
        ...state,
        uuid: action.uuid,
      };
    case UPDATE_STAGE_MODE:
      return {
        ...state,
        stageMode: action.stageMode,
      };
    case UPDATE_BR_DESCRIPTIONS:
      return {
        ...state,
        brDescriptions: action.descriptions,
      };
    case UPDATE_NOOFVIEWERS:
      return {
        ...state,
        noOfViewers: action.noOfViewers,
      };
    case SET_VB_STATUS:
      return {
        ...state,
        vb: action.status,
      };
    case SET_VB_ITEMS_STATUS:
      return {
        ...state,
        vbItems: action.status,
      };
    case SET_VB_DETAILS:
      return {
        ...state,
        vBDetails: action.details,
      };
    case SET_ROOM_LOCK_STATUS:
      return {
        ...state,
        roomLockStatus: action.roomLockStatus,
      };
    case UPDATE_LIVE_STREAMING_STATUS:
      return {
        ...state,
        liveStreamingStatus: action.streamingStatus,
      };
    case UPDATE_BR_STREAMING_STATUS:
      return {
        ...state,
        breakOutRoomStreamings: action.breakOutRoomStreamings,
      };
    default:
      return state;
  }
};

export default confReducer;
