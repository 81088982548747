export const GET_ERRORS = "GET_ERRORS";

//User connection actions
export const SET_URL = "set_url";
export const SET_AUTH = "set_auth";
export const SET_NOTIFICATIONS = "set_notifications";
export const SET_USER = "set_user";
export const SET_PAYPAL = "set_paypal";
export const SET_THEME = "set_theme";
export const SET_CONF_BG_COLOR = "SET_CONF_BG_COLOR";
export const SET_GENERAL_ALERT = "SET_GENERAL_ALERT";
export const SET_MOBILE_MENU = "SET_MOBILE_MENU";
export const SET_FULL_SCREEN = "SET_FULL_SCREEN";

// Kurento one to one call action types
export const NOT_REGISTERED = "NOT_REGISTERED";
export const REGISTERING = "REGISTERING";
export const REGISTER = "REGISTER";
export const USER_REGISTERED = "USER_REGISTERED";
export const SET_WEBRTC_STATE_FOR_WEBRTC_PEER =
  "SET_WEBRTC_STATE_FOR_WEBRTC_PEER";
export const SET_WEBRTC_STATE_FOR_WEBRTC_PEER_FOR_INCOMING_CALL =
  "SET_WEBRTC_STATE_FOR_WEBRTC_PEER_FOR_INCOMING_CALL";
export const STOP_COMMUNICATION = "STOP_COMMUNICATION";
export const IN_CALL = "IN_CALL";
export const PROCESSING_CALL = "PROCESSING_CALL";
export const NO_CALL = "NO_CALL";
export const UPDATE_CALL_STATE = "UPDATE_CALL_STATE";
export const SET_WEBRTC_STATE_FOR_WEBRTC_PEER_SCREENSHARE =
  "SET_WEBRTC_STATE_FOR_WEBRTC_PEER_SCREENSHARE";

// Kurento live streaming action types
export const SET_WEBRTC_STATE_FOR_WEBRTC_PEER_STREAMING =
  "SET_WEBRTC_STATE_FOR_WEBRTC_PEER_STREAMING";
export const STOP_COMMUNICATION_PRESENTER_STREAMING =
  "STOP_COMMUNICATION_PRESENTER_STREAMING";
export const STOP_COMMUNICATION_VIEWER_STREAMING =
  "STOP_COMMUNICATION_VIEWER_STREAMING";
export const PRESENTER_REGISTERED = "PRESENTER_REGISTERED";
export const VIEWER_REGISTERED = "VIEWER_REGISTERED";

export const PARTICIPANT_REGISTERED = "PARTICIPANT_REGISTERED";
export const SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF =
  "SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF";
export const REMOVE_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF =
  "REMOVE_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF";
export const STOP_COMMUNICATION_PARTICIPANT_CONF =
  "STOP_COMMUNICATION_PARTICIPANT_CONF";
export const SET_NAME_ROOMNAME_CONF = "SET_NAME_ROOMNAME_CONF";
export const SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_SECONDARY_CALL =
  "SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_SECONDARY_CALL";
export const REMOVE_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_SECONDARY_CALL =
  "REMOVE_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_SECONDARY_CALL";
export const STOP_COMMUNICATION_PARTICIPANT_CONF_SECONDARY_CALL =
  "STOP_COMMUNICATION_PARTICIPANT_CONF_SECONDARY_CALL";
export const SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_SECONDARY_CALL_STATUS =
  "SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_SECONDARY_CALL_STATUS";
export const SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_IP_CAMERA =
  "SET_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_IP_CAMERA";
export const REMOVE_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_IP_CAMERA =
  "REMOVE_WEBRTC_STATE_FOR_WEBRTC_PARTICIPANT_CONF_IP_CAMERA";

//mediasoup implementation action types
export const PARTICIPANT_REGISTERED_NEW = "PARTICIPANT_REGISTERED_NEW";
export const UPDATE_SEND_TRANSPORT = "UPDATE_SEND_TRANSPORT";
export const UPDATE_MEDIA_PRODUCERS = "UPDATE_MEDIA_PRODUCERS";
export const UPDATE_RECV_TRANSPORT = "UPDATE_RECV_TRANSPORT";
export const UPDATE_CONSUMERS = "UPDATE_CONSUMERS";
export const UPDATE_JOIN_STATUS = "UPDATE_JOIN_STATUS";
export const LEAVE_AND_RESET = "LEAVE_AND_RESET";
export const UPDATE_STATS = "UPDATE_STATS";
export const REMOVE_PEER = "REMOVE_PEER";
export const UPDATE_SCREEN_MEDIA_PRODUCERS = "UPDATE_SCREEN_MEDIA_PRODUCERS";
export const REMOVE_SCREEN_VIDEO_MEDIA_PRODUCER =
  "REMOVE_SCREEN_VIDEO_MEDIA_PRODUCER";
export const REMOVE_SCREEN_AUDIO_MEDIA_PRODUCER =
  "REMOVE_SCREEN_AUDIO_MEDIA_PRODUCER";
export const REMOVE_PEER_MEDIA = "REMOVE_PEER_MEDIA";
export const UPDATE_ROOM_CAPABILITIES = "UPDATE_ROOM_CAPABILITIES";
export const UPDATE_SOCKET = "UPDATE_SOCKET";
export const UPDATE_SHOCKET_SHOULD_RECONNECT =
  "UPDATE_SHOCKET_SHOULD_RECONNECT";
export const SET_TSA = "SET_TSA";
export const CLOSE_ROOM_BY_MODERATOR = "CLOSE_ROOM_BY_MODERATOR";
export const UPDATE_CURRENT_CAMERA_LABEL = "UPDATE_CURRENT_CAMERA_LABEL";
export const UPDATE_VIDEO_STATUS = "UPDATE_VIDEO_STATUS";
export const UPDATE_AUDIO_STATUS = "UPDATE_AUDIO_STATUS";
export const UPDATE_VIDEO_PRODUCER = "UPDATE_VIDEO_PRODUCER";
export const UPDATE_VIDEO_PRIVACY = "UPDATE_VIDEO_PRIVACY";
export const UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_TO_ME =
  "UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_TO_ME";
export const UPDATE_PARTICIPANT_DETAILS = "UPDATE_PARTICIPANT_DETAILS";
export const UPDATE_CHAT_MESSAGES = "UPDATE_CHAT_MESSAGES";
export const UPDATE_CONF_OPTIONS = "UPDATE_CONF_OPTIONS";
export const CLOSE_ROOM_LEAVE_ALERT = "CLOSE_ROOM_LEAVE_ALERT";
export const UPDATE_RIGHT_BAR_COMPONENT = "UPDATE_RIGHT_BAR_COMPONENT";
export const UPDATE_FULLSCREEN = "UPDATE_FULLSCREEN";
export const UPDATE_FULLSCREEN_STATUS = "UPDATE_FULLSCREEN_STATUS";
export const UPDATE_FULLSCREEN_DRAWER_STATUS =
  "UPDATE_FULLSCREEN_DRAWER_STATUS";
export const UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_DETAILS =
  "UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG_DETAILS";
export const UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG =
  "UPDATE_SHOW_USER_USER_AUTHENTICATION_DIALOG";
export const UPDATE_AUTHENTICATION_REQUESTERS =
  "UPDATE_AUTHENTICATION_REQUESTERS";
export const UPDATE_USER_FEEDBACK_MESSAGES = "UPDATE_USER_FEEDBACK_MESSAGES";
export const UPDATE_USER_ERRORS = "UPDATE_USER_ERRORS";
export const UPDATE_RESTART_ICE_IN_PROGRESS_STATUS =
  "UPDATE_RESTART_ICE_IN_PROGRESS_STATUS";
export const UPDATE_CYCLE_CAMERA_BUSY = "UPDATE_CYCLE_CAMERA_BUSY";
export const UPDATE_CALL_START_TIME = "UPDATE_CALL_START_TIME";
export const UPDATE_HAND_RAISE = "UPDATE_HAND_RAISE";
export const UPDATE_CURRENT_MIC_LABEL = "UPDATE_CURRENT_MIC_LABEL";
export const UPDATE_CURRENT_SPEAKER_LABEL = "UPDATE_CURRENT_SPEAKER_LABEL";
export const RESET_PARTICIPANT_SENDING_CAPABILITIES =
  "RESET_PARTICIPANT_SENDING_CAPABILITIES";
export const CREATE_BREAKOUT_ROOMS = "CREATE_BREAKOUT_ROOMS";
export const CREATE_GROUPs_FOR_BREAKOUT_ROOMS =
  "CREATE_GROUPs_FOR_BREAKOUT_ROOMS";
export const UPDATE_BREAKOUT_ROOM_STATUS = "UPDATE_BREAKOUT_ROOM_STATUS";
export const UPDATE_BREAKOUT_ROOM_JOIN_STATUS =
  "UPDATE_BREAKOUT_ROOM_JOIN_STATUS";
export const UPDATE_SHOW_BREAKOUT_ROOM_OPTION =
  "UPDATE_SHOW_BREAKOUT_ROOM_OPTION";
export const UPDATE_SPOTLIGHT = "UPDATE_SPOTLIGHT";
export const UPGRADE_PARTICIPANT_TYPE = "UPGRADE_PARTICIPANT_TYPE";
export const SET_UPGRADE_REQUESTED = "SET_UPGRADE_REQUESTED";
export const UPDATE_RECORDING_STATUS = "UPDATE_RECORDING_STATUS";
export const UPDATE_BR_RECORDING_STATUS = "UPDATE_BR_RECORDING_STATUS";
export const SET_LOADBALANCER_DETAILS = "SET_LOADBALANCER_DETAILS";
export const UPDATE_MEDIA_LABELS = "UPDATE_MEDIA_LABELS";
export const UPDATE_UUID = "UPDATE_UUID";
export const UPDATE_STAGE_MODE = "UPDATE_STAGE_MODE";
export const UPDATE_BR_DESCRIPTIONS = "UPDATE_BR_DESCRIPTIONS";
export const UPDATE_NOOFVIEWERS = "UPDATE_NOOFVIEWERS";
export const SET_VB_STATUS = "SET_VB_STATUS";
export const SET_VB_ITEMS_STATUS = "SET_VB_ITEMS_STATUS";
export const SET_VB_DETAILS = "SET_VB_DETAILS";
export const SET_ROOM_LOCK_STATUS = "SET_ROOM_LOCK_STATUS";
export const UPDATE_LIVE_STREAMING_STATUS = "UPDATE_LIVE_STREAMING_STATUS";
export const UPDATE_BR_STREAMING_STATUS = "UPDATE_BR_STREAMING_STATUS";

// Conf room setting actions

export const CHANGE_CONF_GENERAL_SETTINGS = "CHANGE_CONF_GENERAL_SETTINGS";
export const CHANGE_CONF_AUTH_SETTINGS = "CHANGE_CONF_AUTH_SETTINGS";
export const CHANGE_CONF_PRESENTER_SETTINGS = "CHANGE_CONF_PRESENTER_SETTINGS";
export const CHANGE_CONF_PARTICIPANT_SETTINGS =
  "CHANGE_CONF_PARTICIPANT_SETTINGS";
export const CHANGE_CONF_CHAT_SETTINGS = "CHANGE_CONF_CHAT_SETTINGS";

// site setting action
export const SET_SITE_SETTING = "SET_SITE_SETTING";
export const SET_ROOM_SETTING = "SET_ROOM_SETTING";
export const SET_CURRENT_ROOM_ID = "SET_CURRENT_ROOM_ID";
export const SET_CURRENT_ROOM_NAME = "SET_CURRENT_ROOM_NAME";

// call Records Action
export const SET_CALL_RECORDS = "SET_CALL_RECORDS";
export const UPDATE_CID = "UPDATE_CID";
export const SET_ROOM_SETTINGS = "SET_ROOM_SETTINGS";

// payment action
export const SET_PAYMENTS = "SET_PAYMENTS";

// org users action
export const SET_USERS = "SET_USERS";
export const SET_DELETED_USERS = "SET_DELETED_USERS";

// Breakout section action
export const BREAKOUT_SECTION = "BREAKOUT_SECTION";
export const CHANGE_BREAKOUT_SECTION_GROUPS = "CHANGE_BREAKOUT_SECTION_GROUPS";

// join page setting action
export const JOIN_PAGE_SETING_STATUS = "JOIN_PAGE_SETING_STATUS";

// LANDING page setting
export const LANDING_PAGE_SETING_STATUS = "LANDING_PAGE_SETING_STATUS";
export const tabs = {
  HORIZONTAL_TAB: "HORIZONTAL_TAB",
  VERTICAL_TAB: "VERTICAL_TAB",
};
export const LANDING_PAGE_HOVER_MENU = "LANDING_PAGE_HOVER_MENU";
export const LANDING_PAGE_MIC_HOVER_MENU = "LANDING_PAGE_MIC_HOVER_MENU";
export const LANDING_PAGE_PEOPLE_HOVER_MENU = "LANDING_PAGE_PEOPLE_HOVER_MENU";
export const LANDING_PAGE_CAMERA_HOVER_MENU = "LANDING_PAGE_CAMERA_HOVER_MENU";

// Presence actions

export const UPDATE_HOSTS = "UPDATE_HOSTS";
export const UPDATE_GUEST_REQUESTS = "UPDATE_GUEST_REQUESTS";
export const UPDATE_HOST_REQUESTS = "UPDATE_HOST_REQUESTS";
export const UPDATE_NOTIFICATION_PERMISSION = "UPDATE_NOTIFICATION_PERMISSION";
