import React, { useEffect, useState } from "react";

const Timer = () => {
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [hour, setHour] = useState("00");
  // const [isActive, setIsActive] = useState(props.showTimer);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let intervalId;

    intervalId = setInterval(() => {
      const secondCounter = counter % 60;
      const minuteCounter = Math.floor(counter / 60);
      const hourCounter = Math.floor(counter / (60 * 60));

      const computedSecond =
        String(secondCounter).length === 1
          ? `0${secondCounter}`
          : secondCounter;
      const computedMinute =
        String(minuteCounter).length === 1
          ? `0${minuteCounter}`
          : minuteCounter;
      const computedHour =
        String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter;

      setSecond(computedSecond);
      setMinute(computedMinute);
      setHour(computedHour);
      setCounter((counter) => counter + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [counter]);

  return <>{`${hour}:${minute}:${second}`}</>;
};
export default Timer;
