import React, { Suspense, useEffect } from "react";
import clsx from "clsx";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Loading from "../common/Loading";
import { USER_TYPE_ADMIN, USER_TYPE_MODERATOR } from "../../utils/constants";
import { URL_USER, URL_GET, URL_SITE_SETTING } from "../../utils/constants";
import consoleHelper from "../../utils/consoleHelper";
import { setUser } from "../../actions/connectionActions";
import { fetchSiteSetting } from "../../actions/siteSettingActions";
import urlCall from "../../utils/urlCall";
import ContactSupport from "../common/ContactSupport";
import componentLoader from "../../utils/componentLoader";
import { useMediaQuery, useTheme } from "@mui/material";
import Navigation from "../Navigation/Navigation";

// import Rooms from "../RoomSettings/Rooms";
const DefaultDrawer = React.lazy(() => import("../common/DefaultDrawer"));
const Profile = React.lazy(() => import("../Profile/Profile"));
const ProfileModify = React.lazy(() =>
  import("../ProfileModify/ProfileModify")
);
const PrivateRoute = React.lazy(() =>
  componentLoader(() => import("../common/PrivateRoute"))
);
const Dashboard = React.lazy(() =>
  componentLoader(() => import("../Dashboard/Dashboard"))
);
const DashboardModerator = React.lazy(() =>
  componentLoader(() => import("../Dashboard/DashboardModerator"))
);
const StorageManager = React.lazy(() =>
  componentLoader(() => import("../Storage/LayoutManager"))
);
const ReportsManager = React.lazy(() =>
  componentLoader(() => import("../Reports/ReportsManager"))
);
const GeneralSettings = React.lazy(() =>
  componentLoader(() => import("../Settings/GeneralSettings"))
);
const RoomSettingsManager = React.lazy(() =>
  componentLoader(() => import("../RoomSettings/RoomSettingsManager"))
);
const Rooms = React.lazy(() =>
  componentLoader(() => import("../RoomSettings/Rooms"))
);
// const Navigation = React.lazy(() =>
//   componentLoader(() => import("../Navigation/Navigation"))
// );
const CallLogsLanding = React.lazy(() =>
  componentLoader(() => import("../CallLogs/CallLogsLanding"))
);
const Users = React.lazy(() => componentLoader(() => import("../Users/Users")));
const Error = React.lazy(() =>
  componentLoader(() => import("../Error/Unauthorized"))
);
const TalkNowMod = React.lazy(() =>
  componentLoader(() => import("../TalkNow/TalkNowMod"))
);
const ProspectsAdmin = React.lazy(() =>
  componentLoader(() => import("../Prospects/ProspectsAdmin"))
);
const Help = React.lazy(() => componentLoader(() => import("../Help/Help")));
const Support = React.lazy(() =>
  componentLoader(() => import("../Support/Support"))
);

function Copyright() {
  return (
    <>
      <Typography variant="body2"  align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://centedge.io/">
          Centedge.io
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2"  align="center">
        <Link color="inherit" href="mailto:support@centedge.io">
          support@centedge.io
        </Link>
      </Typography>
    </>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // backgroundColor: theme.palette.background.paper,
    minHeight: "calc(100vh - 68px)",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // height: '100vh',
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const DefaultLayout = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    async function fetchAccountStatus() {
      let res = await urlCall(URL_SITE_SETTING, URL_GET);
      // console.log("res = "+JSON.stringify(res));
      if (res && res.siteSetting) {
        dispatch(fetchSiteSetting(res.siteSetting));
      } else {
        // console.log('site settings not found!!')
        // setSiteSettingStatus(true);
      }
      const userStatusResponse = await urlCall(
        `${URL_USER}/fetchAccountStatus`,
        URL_GET
      );

      if (userStatusResponse && userStatusResponse.success) {
        consoleHelper(
          "User accountstatus in defaultLayout",
          userStatusResponse.success
        );
        const user = {
          ...props.user,
          accountSuspended: userStatusResponse.user.accountSuspended,
        };
        dispatch(setUser(user));
      }
    }
    fetchAccountStatus();
  }, []);

  return (
    <div className={classes.root}>
      <Suspense fallback={<Loading />}>
        <CssBaseline />
        <Navigation />
        <DefaultDrawer
          open={open}
          setOpen={setOpen}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
        />

        {/* <div className={classes.appBarSpacer} /> */}
        <Grid
          container
          direction="column"
          // justifyContent="center"
          alignItems="center"
          className={classes.container}
          sx={{
            "& .MuiInputLabel-root": {
              // color: "#3f51b5",
            },
            "& .MuiButton-root, .MuiTab-root": {
              textTransform: "none",
              fontSize: "16px",
            },
            // "& .MuiButton-contained,.MuiButton-contained:hover ": {
            //   backgroundColor: "#3f51b5",
            // },
            // "& .MuiButton-outlined": {
            //   borderColor: "#3f51b5",
            //   color: "#3f51b5",
            // },
            "& .MuiButton-text": {
              // color: "#3f51b5",
            },
            "& .MuiStepIcon-root,MuiStepIcon-root.Mui-active": {
              // color: "#3f51b5",
            },
            "& .Mui-checked": {
              // color: "#3f51b5",
            },
            "& .MuiTablePagination-selectIcon": {
              color: theme.palette.primary.main,
            },
            "& .MuiTablePagination-select,.MuiTablePagination-select:focus": {
              border: "1px solid",
              borderRadius: "8px",
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          {/* <Route
              path="/"
              // exact
              render={(props) => <Dashboard {...props} />}
            /> */}
          <Grid
            item
            // xs={10}
            // sm={8}
            // md={6}
            sx={{
              marginLeft: matches ? "0px" : open ? "161px" : "56px",
              // maxWidth: "70%",
            }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to={
                      props.user && props.user.role === USER_TYPE_ADMIN
                        ? "/dashboard/admin"
                        : props.user.role === USER_TYPE_MODERATOR
                        ? "/dashboard/mod"
                        : "/dashboard/error"
                    }
                  />
                }
              />
              {props.user && props.user.role === USER_TYPE_ADMIN ? (
                <Route
                  path="/admin"
                  element={<PrivateRoute component={Dashboard} />}
                />
              ) : props.user.role === USER_TYPE_MODERATOR ? (
                <Route
                  path="/mod"
                  element={<PrivateRoute component={DashboardModerator} />}
                />
              ) : (
                <Route path="/error" element={Error} />
              )}

              <Route
                path="/profile"
                element={<PrivateRoute component={Profile} />}
              />
              <Route
                path="/profile/modify"
                element={<PrivateRoute component={ProfileModify} />}
              />
              <Route
                path="/storage"
                element={<PrivateRoute component={StorageManager} />}
              />
              <Route
                path="/reports"
                element={<PrivateRoute component={ReportsManager} />}
              />
              <Route
                path="/calls"
                element={<PrivateRoute component={CallLogsLanding} />}
              />
              <Route
                path="/meetnow"
                element={<PrivateRoute component={TalkNowMod} />}
              />
              <Route
                path="/prospects"
                element={<PrivateRoute component={ProspectsAdmin} />}
              />
              <Route path="/help" element={<PrivateRoute component={Help} />} />
              <Route
                path="/support"
                element={<PrivateRoute component={Support} />}
              />
              {/* <PrivateRoute
                path="/rooms/room-settings"
                component={RoomSettingsManager}
                authenticated={props.authenticated}
              /> */}
              {props.user && props.user.role === USER_TYPE_ADMIN ? (
                <>
                  <Route
                    path="/rooms"
                    element={<PrivateRoute component={Rooms} />}
                  />
                  <Route
                    path="/users"
                    element={<PrivateRoute component={Users} />}
                  />
                  <Route
                    path="/settings"
                    element={<PrivateRoute component={GeneralSettings} />}
                  />
                </>
              ) : (
                <Route path="/error" element={Error} />
              )}
              <Route path="*" element={<Navigate to="/notFound" />} />
            </Routes>

            <Box
              pt={4}
              sx={{
                // color: "#fff",
                "& .MuiIconButton-root": {
                  color: theme.palette.common.blue,
                },
              }}
            >
              <ContactSupport />
            </Box>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Grid>
        </Grid>
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.connection.authenticated,
    user: state.connection.user,
  };
};

/*   const mapDispatchToProps = dispatch => {
    return {
      fetchSiteSetting: siteSetting => {
        dispatch(fetchSiteSetting(siteSetting));
      }
    };
  };
  const mapstateToProps = state => {
    return {
      authenticated: state.connection.authenticated,
      url: state.connection.url,
      user: state.connection.user,
      siteSetting: state.siteSetting
    };
  };
  export default connect(mapstateToProps, mapDispatchToProps)(DefaultLayout); */
export default connect(mapStateToProps)(DefaultLayout);
